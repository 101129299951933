"use strict";
/*!
 *
 *  page2top.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$elem = $('#Page2Top');
        this.$footer = $('#Footer');
        this.isStatic = false;
        this.isVisible = false;
        this.borderLine = 0;
        this.$pr = $('#PR');
        this.$prBtn = $('#PRBtn');
        this.PRisVisible = false;
        this.PRisHide = false;
        this.PRisHide = this.$prBtn.length > 0 ? false : true;
        $(window).on('scroll', function (e) {
            var top = $(e.currentTarget).scrollTop();
            var bottom = top + util.wh;
            if (!_this.isVisible && top > 0) {
                _this.isVisible = true;
                _this.$elem.addClass('-visible');
            }
            ;
            if (_this.isVisible && top <= 0) {
                _this.isVisible = false;
                _this.$elem.removeClass('-visible');
            }
            ;
            if (!_this.isStatic && bottom >= _this.getBorder()) {
                _this.isStatic = true;
                _this.$elem.addClass('-static');
            }
            ;
            if (_this.isStatic && bottom < _this.getBorder()) {
                _this.isStatic = false;
                _this.$elem.removeClass('-static');
            }
            ;
            if (!_this.PRisVisible && top > 0 && !_this.PRisHide) {
                _this.PRisVisible = true;
                _this.$pr.attr('aria-hidden', 'false');
                $('body').addClass('sw-ShowPR');
            }
            ;
        });
        this.$prBtn.on('click', function (e) {
            e.preventDefault();
            _this.$pr.addClass('-hide');
            $('body').addClass('sw-HidePR');
            setTimeout(function () {
                _this.$pr.attr('aria-hidden', 'true');
                $('body').removeClass('sw-ShowPR sw-HidePR');
            }, 500);
        });
    }
    default_1.prototype.getBorder = function () {
        return this.$footer.offset().top
            + this.$elem.height()
            + 20;
    };
    return default_1;
}());
exports.default = default_1;
