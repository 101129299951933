"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown = exports.Menu = void 0;
var Menu = /** @class */ (function () {
    function Menu() {
        var _this = this;
        this.$menu = $('#Search, #Menu');
        this.$btn = $('#HeaderMenu, #HeaderSearch');
        this.top = 0;
        this.checkViewport();
        $(window).on('viewportChanged', function () {
            _this.checkViewport();
        });
        this.$btn.on('click', function (e) {
            e.preventDefault();
            var menu = $(e.currentTarget).attr('aria-controls');
            var other = menu === 'Search' ? 'Menu' : 'Search';
            var $menu = $('#' + $(e.currentTarget).attr('aria-controls'));
            var $other = $('#' + other);
            if ($other.is('[aria-expanded="true"]')) {
                _this.close($('#Header' + other), $other);
            }
            ;
            if ($menu.is('[aria-expanded="true"]')) {
                _this.close($(e.currentTarget), $menu);
            }
            else {
                _this.open($(e.currentTarget), $menu);
            }
            ;
        });
        $('#Menu a').on('click', function (e) {
            e.preventDefault();
            if ($('#Menu').is('[aria-expanded="true"]')) {
                _this.close($('#HeaderMenu'), $('#Menu'));
            }
            ;
            var href = $(e.currentTarget).attr('href');
            var hash = '#' + href.replace(/(.+)\#(.+)/, '$2');
            var _href = href.replace(/\#(.+)/, '');
            var path = location.pathname + location.search;
            if (decodeURI(_href) === decodeURI(path)) {
                if ($(hash).is('.c-Tab_Contents')) {
                    $('.c-Tab_Nav_Item').filter(function (i, elem) {
                        return $(elem).attr('href') === hash;
                    }).trigger('click');
                }
                ;
            }
            else {
                location.href = href;
            }
            ;
        });
    }
    Menu.prototype.open = function ($btn, $menu) {
        $btn.attr('aria-pressed', 'true');
        $menu.attr('aria-expanded', 'true').css('height', 'calc(var(--vh, 1vh) * 100)');
        $menu.children('.sub-Menu_Inner').css('height', '100%');
        $('html').css({
            overflow: 'hidden'
        });
        document.addEventListener('touchmove', this.scrollControll, {
            passive: false
        });
    };
    Menu.prototype.close = function ($btn, $menu) {
        $btn.attr('aria-pressed', 'false');
        $menu.attr('aria-expanded', 'false');
        $menu.children('.sub-Menu_Inner').css('height', '0');
        $('html').css({
            overflow: ''
        });
        document.removeEventListener('touchmove', this.scrollControll);
    };
    Menu.prototype.scrollControll = function (e) {
        var $inner = $(e.target).closest('.sub-Search_Inner, .sub-Menu_Inner');
        if ($inner.length > 0) {
            if ($inner.get(0).scrollHeight > $inner.innerHeight()) {
                e.stopPropagation();
            }
            else {
                e.preventDefault();
            }
            ;
        }
        else {
            e.preventDefault();
        }
    };
    Menu.prototype.checkViewport = function () {
        this.$menu.removeClass('-transition');
        if (util.viewport !== 'phone' && util.viewport !== 'tablet') {
            this.$btn.attr('aria-pressed', 'false');
            this.$menu.removeAttr('aria-expanded').css('height', 'auto');
            this.$menu.children('.sub-Menu_Inner').css('height', 'auto');
            $('html').css({
                overflow: ''
            });
            document.removeEventListener('touchmove', this.scrollControll);
        }
        else {
            this.$menu.attr('aria-expanded', 'false').addClass('-transition');
        }
        ;
    };
    return Menu;
}());
exports.Menu = Menu;
;
var DropDown = /** @class */ (function () {
    function DropDown() {
        var _this = this;
        this.$item = $('.st-Global_Item').filter(function (i, elem) {
            return $(elem).children('.st-Local').length > 0;
        });
        this.$link = $('.st-Global_Head > .st-Global_Link');
        this.$btn = $('.st-Global_Btn');
        $(window).on('viewportChanged', function () {
            _this.reset();
        });
        this.$item.on('mouseenter', function (e) {
            if (!util.isTouchDevice && util.viewport !== 'phone') {
                _this.open(e.currentTarget);
            }
            ;
        }).on('mouseleave', function (e) {
            if (!util.isTouchDevice && util.viewport !== 'phone') {
                _this.close(e.currentTarget);
            }
            ;
        });
        this.$link.on('click', function (e) {
            if (util.isTouchDevice && util.viewport !== 'phone') {
                var $item = $("#" + $(e.currentTarget).attr('aria-controls'));
                if ($item.is('[aria-expanded="false"]')) {
                    e.preventDefault();
                    _this.$item.each(function (i, elem) {
                        if ($(elem).is('[aria-expanded="true"]')) {
                            _this.close(elem);
                        }
                        ;
                    });
                    _this.open($item[0]);
                }
                ;
            }
            ;
        });
        this.$btn.on('click', function (e) {
            var $item = $("#" + $(e.currentTarget).attr('aria-controls'));
            if ($item.is('[aria-expanded="false"]')) {
                _this.open($item[0]);
            }
            else {
                _this.close($item[0]);
            }
            ;
        });
        $(document).on('click', function (e) {
            if (util.isTouchDevice && util.viewport !== 'phone') {
                if (!$(e.target).closest('.st-Global_Item[aria-expanded="true"]').length) {
                    _this.close($('.st-Global_Item[aria-expanded="true"]')[0]);
                }
                ;
            }
            ;
        });
    }
    DropDown.prototype.open = function (elem) {
        var $local = $(elem).children('.st-Local');
        $(elem).attr('aria-expanded', 'true');
        $local.stop(true, false).slideDown(500, 'easeOutExpo');
    };
    DropDown.prototype.close = function (elem) {
        var $local = $(elem).children('.st-Local');
        $(elem).attr('aria-expanded', 'false');
        $local.stop(true, false).slideUp(500, 'easeOutExpo');
    };
    DropDown.prototype.reset = function () {
        this.$item.attr('aria-expanded', 'false');
        $('.st-Local').removeAttr('style');
    };
    return DropDown;
}());
exports.DropDown = DropDown;
