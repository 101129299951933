"use strict";
/*!
 *
 *  pagination.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem) {
        this.$elem = $(elem);
        if (this.$elem.is('.sch-Contents *')) {
            return;
        }
        this.current = Number(this.$elem.data('current'));
        var limit = this.$elem.children().length;
        var x = 0;
        var y = 0;
        this.$elem.children().each(function (i, item) {
            if (i === 3) {
                x = Number($(item).text()) - 1;
            }
            ;
            if (i === limit - 2) {
                y = Number($(item).text());
            }
            ;
        });
        var min = this.current - 2;
        var max = this.current + 2;
        if (min < 1) {
            max += 1 - min;
        }
        ;
        if (max > y) {
            min += -(max - y);
        }
        ;
        var min2 = min - 2;
        var max2 = max + 2;
        this.$elem.children('[aria-disabled="true"]').on('click', function (e) {
            e.preventDefault();
        });
        this.$elem.children().each(function (i, item) {
            if (i > 0 && i < 3) {
                if (min2 <= 0) {
                    $(item).attr('aria-hidden', 'true');
                }
                ;
            }
            else if (i < limit - 3) {
                if ((i + x + 1 - 3) >= min && (i + x + 1 - 3) <= max) {
                }
                else {
                    $(item).attr('aria-hidden', 'true');
                }
                ;
            }
            else if (i === limit - 1) {
            }
            else {
                if (max2 >= y) {
                    $(item).attr('aria-hidden', 'true');
                }
            }
            ;
        });
    }
    return default_1;
}());
exports.default = default_1;
