"use strict";
/*!
 *
 *  tab.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(option) {
        var _this = this;
        this.def = {
            elem: '.c-Tab',
            navClass: '.c-Tab_Nav, .ing-Nav',
            cntClass: '.c-Tab_Body'
        };
        this.setting = $.extend({}, this.def, option ? option : {});
        this.$elem = $(this.setting.elem);
        if (this.$elem.length) {
            this.$elem.each(function (i, elem) {
                new TabCore(elem, _this.setting);
            });
            $('.sw-TabLink').on('click', function (e) {
                e.preventDefault();
                var target = $(e.currentTarget).attr('href');
                var $tab = $(target).closest(_this.setting.elem);
                var $link = $tab.find(_this.setting.navClass).find('a[href="' + target + '"]');
                $link.trigger('click');
                var offset = $tab.offset();
                var top = offset !== undefined ? offset.top : 0;
                $(util.scroller).animate({
                    scrollTop: top - util.scrollGap()
                }, {
                    duration: 700,
                    easing: 'easeOutExpo',
                    queue: false
                });
            });
        }
        ;
    }
    return default_1;
}());
exports.default = default_1;
;
var TabCore = /** @class */ (function () {
    function TabCore(elem, setting) {
        var _this = this;
        this.hasHashCurrent = false;
        this.innerLoad = 0;
        this.$elem = $(elem);
        this.$nav = $(elem).children(setting.navClass);
        this.hash = util.hash;
        if (this.hash.search('--') !== -1) {
            this.hash = this.hash.split('--')[0];
        }
        ;
        if (this.getParam('c') !== null) {
            this.hash = '#' + this.getParam('c');
        }
        ;
        this.$a = this.$nav.find('a').filter(function (i, elem) {
            return $(elem).is('[href^="#"]');
        });
        this.$contents = $(elem).children(setting.cntClass);
        this.$a.each(function (i, item) {
            var _href = $(item).attr('href');
            if (_href === _this.hash) {
                _this.hasHashCurrent = true;
            }
            ;
        });
        this.setDefault();
        this.setEvent();
    }
    TabCore.prototype.setDefault = function () {
        var _this = this;
        var $link;
        var $contents;
        this.$a.each(function (i, elem) {
            var _href = $(elem).attr('href');
            if (_this.hasHashCurrent) {
                if (_href === _this.hash) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            else {
                if (i === 0) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            ;
        });
        this.$a.attr('aria-selected', 'false');
        this.$contents.children().attr('aria-hidden', 'true');
        $link.attr('aria-selected', 'true');
        $contents.attr('aria-hidden', 'false');
        if (this.hasHashCurrent) {
            $(window).on('load', function () {
                history.replaceState(null, null, util.hash);
            });
        }
        ;
        if (this.$contents.find('.sch-Contents').length) {
            this.innerLoad = this.$contents.find('.sch-Contents').length;
        }
        ;
        if (this.innerLoad === 0) {
            setTimeout(function () {
                _this.$elem.addClass('-loaded');
            }, 500);
        }
        else {
            this.$elem.on('innerLoaded', function () {
                if (--_this.innerLoad > 0)
                    return;
                _this.$elem.addClass('-loaded');
            });
        }
        ;
    };
    TabCore.prototype.setEvent = function () {
        var _this = this;
        this.$a.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(e.currentTarget).is('[aria-selected="true"]'))
                return false;
            var _href = $(e.currentTarget).attr('href');
            _this.$nav.find('[aria-selected="true"]').attr('aria-selected', 'false');
            _this.$contents.children('[aria-hidden="false"]').attr('aria-hidden', 'true');
            $(e.currentTarget).attr('aria-selected', 'true');
            $(_href).attr('aria-hidden', 'false');
            history.replaceState(null, null, _href);
        });
    };
    TabCore.prototype.getParam = function (name, url) {
        if (!url)
            url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
        if (!results)
            return null;
        if (!results[2])
            return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };
    return TabCore;
}());
// import Vue from 'vue';
// export default class {
// 	tab: Vue;
// 	ids: string[] = [];
// 	hash: string = location.hash.replace('#', '');
// 	constructor(elem: HTMLElement) {
// 		$(elem).find('.c-Tab_Contents').each((i: number, el: HTMLElement) => {
// 			this.ids.push($(el).attr('id'));
// 		});
// 		let current = this.ids[0];
// 		if (this.ids.find(val => val == this.hash)) {
// 			current = this.hash;
// 		};
// 		this.tab = new Vue({
// 			el: elem,
// 			data: {
// 				current: current,
// 				isLoaded: false
// 			},
// 			mounted: function() {
// 				setTimeout(() => {
// 					this.isLoaded = true;
// 				}, 500);
// 			},
// 			methods: {
// 				changeTab: function(id: string) {
// 					this.current = id;
// 				}
// 			}
// 		})
// 		console.log(current)
// 	}
// };
