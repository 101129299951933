"use strict";
/*!
 *
 *  nutrition.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem) {
        var _this = this;
        this.maxHeight = 'none';
        this.height = 'none';
        this.isExpanded = false;
        this.$elem = $(elem);
        this.$inner = this.$elem.children('.rcp-Nutrition_Inner');
        this.$table = this.$inner.children('table');
        this.$btn = this.$elem.children('.rcp-Nutrition_Btn');
        this.setHeight();
        this.changeHeight();
        this.$btn.on('click', function (e) {
            e.preventDefault();
            _this.isExpanded = !_this.isExpanded;
            if (_this.isExpanded) {
                _this.$btn.text('縮小する').attr('aria-pressed', 'true');
            }
            else {
                _this.$btn.text('もっと見る').attr('aria-pressed', 'false');
            }
            ;
            _this.changeHeight();
        });
        $(window).on('resize', function () {
            _this.setHeight();
            _this.changeHeight();
        }).on('load', function () {
            _this.setHeight();
        });
    }
    default_1.prototype.setHeight = function () {
        var _this = this;
        if (util.viewport !== 'phone') {
            this.maxHeight = 'none';
            this.height = 'none';
        }
        else {
            this.maxHeight = 0;
            this.height = 0;
            this.$table.find('tr').each(function (i, tr) {
                if (typeof _this.maxHeight === 'number') {
                    _this.maxHeight += $(tr).height();
                }
                ;
                if (i < 5 && typeof _this.height === 'number') {
                    _this.height += $(tr).height();
                }
                ;
            });
            this.maxHeight += 1;
            this.height += 1;
        }
        ;
    };
    default_1.prototype.changeHeight = function () {
        if (util.viewport !== 'phone') {
            this.$inner.css('max-height', this.height);
        }
        else {
            if (this.isExpanded) {
                this.$inner.css('max-height', this.maxHeight);
            }
            else {
                this.$inner.css('max-height', this.height);
            }
            ;
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
